.scroll{
    position: fixed;
    bottom: 50vb; /* Ajusta esta propiedad para cambiar la distancia desde la parte inferior */
    right: 3vh; /* Ajusta esta propiedad para cambiar la distancia desde la parte derecha */
    z-index: 9999; /* Asegura que el ícono esté por encima de otros elementos */
    background-color: #2e8fbd; /* Color de fondo del ícono */
    color: #ffffff; /* Color del ícono */
    width: 50px; /* Ancho del ícono */
    height: 50px; /* Alto del ícono */
    border-radius: 50%; /* Hace que el ícono sea circular */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}