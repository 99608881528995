/*===== FOOTER ONE =====*/
.footer-one {
    background-color: #001143
    ;
  }
  .footer-one .footer-logo {
    margin-top: 30px;
  }
  .footer-one .footer-widget {
    padding-top: 70px;
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-one .footer-widget {
      padding-top: 40px;
      padding-bottom: 70px;
    }
  }
  @media (max-width: 767px) {
    .footer-one .footer-widget {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }
  .footer-one .footer-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: var(--black);
    position: relative;
  }
  .footer-one .footer-app-store {
    padding-top: 22px;
  }
  .footer-one .footer-app-store .download-title {
    font-size: 15px;
    display: block;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--dark-1);
  }
  .footer-one .footer-app-store li {
    display: inline-block;
    margin-right: 8px;
  }
  .footer-one .footer-app-store li img {
    max-width: 140px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-one .footer-app-store li {
      margin-right: 6px;
    }
  }
  .footer-one .footer-app-store li:last-child {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    .footer-one .footer-app-store li {
      width: 120px;
      display: block;
      margin-bottom: 10px;
    }
    .footer-one .footer-app-store li:last-child {
      margin: 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-one .footer-app-store li {
      width: auto;
    }
  }
  .footer-one .footer-app-store li a {
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -ms-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
  }
  .footer-one .footer-app-store li a:hover {
    box-shadow: var(--shadow-4);
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -o-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  .footer-one .f-about {
    margin-top: 30;
  }
  .footer-one .f-about .text {
    margin-top: 20px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 1400px) {
    .footer-one .f-about {
      padding-right: 200px;
    }
  }
  .footer-one .footer-link {
    margin-top: 30px;
  }
  .footer-one .footer-link ul {
    margin-top: 8px;
  }
  .footer-one .footer-link ul a {
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-3);
    margin-top: 16px;
    font-weight: 400;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  @media (max-width: 767px) {
    .footer-one .footer-link ul a {
      margin-top: 12px;
    }
  }
  .footer-one .footer-link ul a:hover {
    color: var(--primary);
  }
  .footer-one .footer-contact {
    margin-top: 30px;
  }
  .footer-one .footer-contact ul {
    margin-top: 24px;
  }
  .footer-one .footer-contact ul li {
    display: block;
    margin-top: 15px;
    position: relative;
    padding-left: 24px;
    color: var(--dark-3);
  }
  .footer-one .footer-contact ul li:first-child {
    margin: 0;
  }
  .footer-one .footer-contact ul li i {
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 4px;
  }
  .footer-one .footer-copyright {
    border-top: 1px solid var(--gray-4);
  }
  .footer-one .copyright {
    padding-bottom: 23px;
    padding-top: 13px;
  }
  .footer-one .copyright .text {
    color: white;
    margin-top: 10px;
  }
  .footer-one .social {
    margin-top: 10px;
  }
  .footer-one .social li {
    display: inline-block;
    margin-right: 15px;
  }
  .footer-one .social li:last-child {
    margin-right: 0;
  }
  .footer-one .social li a {
    font-size: 18px;
    color: var(--dark-3);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .footer-one .social li a:hover {
    color: var(--primary);
  }