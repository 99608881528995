body, html {
  overflow-x: hidden;
}
.container {
  max-width: 1200px; /* El contenedor no excederá 1200px de ancho */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.header {
  position: relative;
  min-height: 100vh;
  background-image: url('../../../public/assets/civil2_auto_x2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  }

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */
}


.header .navbar-area {
  /*background-color: transparent !important;*/
  background-color: #E6E6E6;
  
  
  position: fixed; /* Cambiamos a 'fixed' para que el navbar esté siempre arriba */
  top: 0; /* Asegura que el navbar se coloque en la parte superior */
  left: 0; /* Asegura que el navbar comience desde la izquierda */
  width: 100%; /* Ocupa todo el ancho de la pantalla */
  z-index: 9999; 

}

.headertitle {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.headertitle h1 {
  color: #E6E6E6;
}
.logo{
  position: absolute;
  top: 0;
  left: 2%;

}

/* ===== Buttons Css ===== */
.primary-btn {
    background: var(--primary);
    color: var(--white);
    box-shadow: var(--shadow-2);
  }
  .active.primary-btn, .primary-btn:hover, .primary-btn:focus {
    background: var(--primary-dark);
    color: var(--white);
    box-shadow: var(--shadow-4);
  }
  .deactive.primary-btn {
    background: var(--gray-4);
    color: var(--dark-3);
    pointer-events: none;
  }
  
  .primary-btn-outline {
    border-color: var(--primary);
    color: var(--primary);
  }
  .active.primary-btn-outline, .primary-btn-outline:hover, .primary-btn-outline:focus {
    background: var(--primary-dark);
    color: var(--white);
  }
  .deactive.primary-btn-outline {
    color: var(--dark-3);
    border-color: var(--gray-4);
    pointer-events: none;
  }
  
  /*===========================
    NAVBAR css 
  ===========================*/
  .navbar-toggler:focus {
    box-shadow: none;
  }
  
  .mb-100 {
    margin-bottom: 100px;
  }
  
  /*===== NAVBAR ONE =====*/
  .navbar-one {
    background: var(--primary);
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one {
      padding: 20px 0;
    }
  }
  .navbar-one .navbar {
    position: relative;
    padding: 0;
  }
  .navbar-one .navbar .navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #001143;
    margin: 5px 0;
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .navbar-one .navbar .navbar-toggler.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
  }
  .navbar-one .navbar .navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
  }
  .navbar-one .navbar .navbar-toggler.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
   
    .navbar-one .navbar .navbar-collapse {
      position: absolute;
      top: 140%;
      left: 20%;
      width: 100%;
      background-color: #001143;
      z-index: 8;
      padding: 10px 0;
      border-radius: 0 0 5px 5px;
    }
    
  }
  
  .navbar-one .navbar .navbar-nav .nav-item {
    margin: 0 5px;
    position: relative;
  }
  .navbar-one .navbar .navbar-nav .nav-item a {
    cursor: pointer; /* Cambia el cursor a una mano cuando se pasa sobre los botones */

    font-size: 1.5vh;
    line-height: 24px;
    font-weight: 600;
    padding: 28px 15px;
    color: #001143;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .navbar-one .navbar .navbar-nav .nav-item a::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 0%;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .navbar-one .navbar .navbar-nav .nav-item a.active::before {
    opacity: 0.1;
    visibility: visible;
    width: 100%;
  }
  .navbar-one .navbar .navbar-nav .nav-item a:hover::before {
    opacity: 0.1;
    visibility: visible;
    width: 100%;
  }
  .navbar-one .navbar .navbar-nav .nav-item a i {
    font-weight: 700;
    padding-left: 8px;
    font-size: 14px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item a {
      padding: 10px 10px;
      display: block;
      color: white;
    }
    .navbar-one .navbar .navbar-nav .nav-item a::before {
      border-radius: 5px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item {
      margin-bottom: 5px;
    }
    .navbar-one .navbar .navbar-nav .nav-item:last-child {
      margin-bottom: 0;
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 260px;
    background-color: var(--white);
    box-shadow: var(--shadow-4);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: 99;
    padding: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu {
      position: relative !important;
      width: 100% !important;
      left: 0 !important;
      top: auto !important;
      opacity: 1 !important;
      visibility: visible !important;
      right: auto;
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transition: all none ease-out 0s;
      -moz-transition: all none ease-out 0s;
      -ms-transition: all none ease-out 0s;
      -o-transition: all none ease-out 0s;
      transition: all none ease-out 0s;
      box-shadow: none;
      text-align: left;
      border-top: 0;
      height: 0;
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    height: auto;
    display: block;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
      height: 0;
      display: none;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu.show {
      height: auto;
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li {
    position: relative;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a {
      border-radius: 5px !important;
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    font-weight: 500;
    position: relative;
    color: var(--dark);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: 5;
    text-transform: capitalize;
    border-radius: 5px;
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a:hover {
    background-color: var(--primary);
    color: var(--white);
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a::before {
    display: none;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a:hover {
      background-color: var(--primary-dark);
      color: var(--white);
    }
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a i {
      color: var(--primary-dark);
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
    }
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a:hover i {
      color: var(--white);
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a i {
    font-size: 14px;
    font-weight: 700;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a i {
      display: none;
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li a .sub-nav-toggler i {
    display: inline-block;
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
    right: auto;
    left: 100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 1400px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
      margin-left: 10px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
      padding-left: 30px;
      height: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-menu li .sub-menu.show {
      visibility: visible;
      height: auto;
      position: relative;
    }
  }
  .navbar-one .navbar .navbar-nav .nav-item .sub-menu li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  .navbar-one .navbar .navbar-nav .nav-item:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .navbar-one .navbar .navbar-nav .nav-item .sub-nav-toggler {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px 14px;
      font-size: 16px;
      background: none;
      border: 0;
      color: var(--white);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-one .navbar .navbar-btn {
      position: absolute;
      right: 70px;
      top: 3px;
    }
  }
  @media (max-width: 767px) {
    .navbar-one .navbar .navbar-btn {
      position: absolute;
      right: 60px;
      top: 3px;
    }
  }
  .navbar-one .navbar .navbar-btn ul {
    display: flex;
  }
  .navbar-one .navbar .navbar-btn ul li {
    display: inline-block;
    margin-right: 12px;
  }
  .navbar-one .navbar .navbar-btn ul li a {
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0;
  }
  .navbar-one .navbar .navbar-btn ul li a.primary-btn-outline {
    border-color: var(--white);
    color: var(--white);
  }
  .navbar-one .navbar .navbar-btn ul li a.primary-btn-outline:hover {
    background-color: var(--white);
    color: var(--primary);
  }
  .navbar-one .navbar .navbar-btn ul li a.primary-btn {
    background-color: var(--white);
    color: var(--primary);
    border-color: var(--white);
  }
  .navbar-one .navbar .navbar-btn ul li a.primary-btn:hover {
    background-color: transparent;
    color: var(--white);
  }